.finance-kpi {
  border: 1px solid $gray-200;
  margin-left: 0rem;
  margin-right: 0rem;
  .kpi-title {
    font-size: .9rem;
  }
  .kpi-number {
    font-size: 1.1rem;
  }
}

.chevron-icon {
  -moz-transition: all .3s linear;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
  font-size: 1.2rem;
  &.chevron-icon-down {
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

.document-form {
  .has-danger {
    border-color: theme-color(danger);
  }
}

.history-filter {
  min-height: calc(100vh - #{$footer-height} - #{$navbar-height});
  border-left: 1px solid $gray-200;
  //! scroll?
  // max-height: calc(100vh - #{$footer-height} - #{$navbar-height});
  // overflow-y: auto;
}

.history-row {
  &:hover {
    background-color: $gray-100;
    cursor: pointer;
  }
}

.icon-wrapper {
  width: 40px;
  text-align: center;
}

.muted-icon {
  position: absolute;
  top: 0;
  left: 0;
  color: $gray-400;
}