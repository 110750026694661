/* Reset Styles */
body {
  padding: 0;
  margin: 0;
  // overflow-x: hidden;
}

.form-control,
.form-control:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none
}

.form-control {
  box-shadow: none
}

.form-control:focus {
  outline: 0;
  box-shadow: none
}

a,
div,
h1,
h2,
h3,
h4,
h5,
p,
span {
  text-shadow: none
}

[type=button]:focus,
a:active,
a:focus,
a:visited,
button::-moz-focus-inner,
input[type=reset]::-moz-focus-inner,
input[type=button]::-moz-focus-inner,
input[type=submit]::-moz-focus-inner,
// input[type=file]>input[type=button]::-moz-focus-inner,
select::-moz-focus-inner {
  outline: 0
}

// input,
.form-control:focus,
input:focus,
select:focus,
button:focus,
textarea:focus {
  outline: none;
  outline-width: 0;
  outline-color: transparent;
  box-shadow: none;
  outline-style: none;
}

textarea {
  resize: none;
  overflow-x: hidden;
}

.btn,
.btn-group.open .dropdown-toggle,
.btn:active,
.btn:focus,
.btn:hover,
.btn:visited,
a,
a:active,
a:checked,
a:focus,
a:hover,
a:visited,
body,
button,
button:active,
button:hover,
button:visited,
div,
input,
input:active,
input:focus,
input:hover,
input:visited,
select,
select:active,
select:focus,
select:visited,
textarea,
textarea:active,
textarea:focus,
textarea:hover,
textarea:visited {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus,
button,
button:active,
button:checked,
button:focus,
button:hover,
button:visited {
  outline: 0;
  outline-offset: 0
}

.dropdown-menu>li>a:active,
.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:visited {
  outline: 0;
}

a:focus,
input:focus {
  border-color: transparent;
  outline: none
}
a:focus {
  border-color: transparent;
  outline: none
}

legend {
  width: inherit;
  font-size: inherit;
  margin-bottom: 0;
}
