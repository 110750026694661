/* Utilities */
.img-lg {
  width: 92px;
  height: 92px;
}

.img-md {
  width: 60px;
  height: 60px;
}

.img-sm {
  width: 43px;
  height: 43px;
}

.img-xs {
  width: 37px;
  height: 37px;
}

.img-ss {
  width: 26px;
  height: 26px;
}

.stretch-card {
  @include display-flex;
  @include align-items(stretch);
  @include justify-content(stretch);
  >.card{
    width: 100%;
    min-width: 100%;
  }
}

.border-top { border-top: $border-width solid $border-color; }
.border-bottom { border-bottom: $border-width solid $border-color; }
.border-right { border-right: $border-width solid $border-color; }
.border-left { border-left: $border-width solid $border-color; }

.border-2 { border-width: 2px !important }

.border-right-sm {
  @media (min-width: 576px) {
    border-right: $border-width solid $border-color;
  }
}

.border-right-md {
  @media (min-width: 768px) {
    border-right: $border-width solid $border-color;
  }
}

.border-right-lg {
  @media (min-width: 992px) {
    border-right: $border-width solid $border-color;
  }
}

.border-left-sm {
  @media (min-width: 576px) {
    border-left: $border-width solid $border-color;
  }
}

.border-left-md {
  @media (min-width: 768px) {
    border-left: $border-width solid $border-color;
  }
}

.border-left-lg {
  @media (min-width: 992px) {
    border-left: $border-width solid $border-color;
  }
}

.text-gray { color: $gray-700; }
.text-black { color: $black; }
.flex-grow { flex-grow: 1; }
.bg-transparent { background: transparent; }
.rounded-lg { border-radius: 30px !important; }
.lh-0 { line-height: 0; }
