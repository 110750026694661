.message {
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
}

.message-container {
  border: 1px solid $gray-400;
  border-radius: 6px;
}

#message-container {
  max-height: 460px;
  overflow: auto;
  padding: 16px;
}