.ql-editor {
  strong {
    font-family: $type1-bold;
  }

  ol li,
  ul li {
    font-family: $type1-regular;
  }

  p {
    font-size: 1rem;
    font-family: $type1-regular;
  }
}
