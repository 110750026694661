/* Pagination */
.pagination {
  .page-item-first, .page-item-last {
    width: 30px;
    @media (max-width: 576px) {
      width: 22px;
    }
  }

  .page-item {
    .page-link {
      border-color: $border-color;
      padding: 16px;
      color: color(black);
      font-size: .875rem;
      &:not([disabled]) {
        cursor: pointer;
      }

      @include transition-duration(0.3s);
      &:focus {
        background: inherit;
      }

      i {
        &:before {
          font-size: inherit;
          line-height: 1;
          vertical-align: middle;
        }
      }

      &.disabled,
      &[disabled],
      &:disabled {
        opacity: .3;
      }

      @media (max-width: 768px) {
        padding: 16px 10px;
      }
    }

    &.active,
    &:hover,
    &:focus,
    &:active {
      .page-link {
        background: $primary;
        border-color: $primary;
        color: color(white);
        z-index: 0;
      }
    }
  }

  &.rounded-flat {
    .page-item {
      margin-right: 3px;
      margin-left: 3px;

      .page-link {
        border: none;
        @include border-radius(50px);
      }
    }
  }
}

/* pagination variations */
@each $color, $value in $theme-colors {
  .pagination-#{$color} {
    @include pagination-variants($value);
  }
}

.pagination-wrapper {
  z-index: 100;
}