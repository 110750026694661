// FONTS
//PT Sans

@font-face {
  font-family: 'PTSans-italic';
  src: url('../assets/fonts/PTSans/PTSans-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'PTSans-regular';
  src: url('../assets/fonts/PTSans/PTSans-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PTSans-bold';
  src: url('../assets/fonts/PTSans/PTSans-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
