/* Forms */
.form-control {
  border: 1px solid $border-color;
  font-family: $type1-regular;
  font-size: $input-font-size;
  padding: $input-padding-y $input-padding-x;
  height: 50px;
  border-radius: 3px;

  &:disabled,
  &[readonly] {
    background-color: $gray-100 !important;
    border-color: $gray-100 !important;
    cursor: auto;
    opacity: 1;
  }
  &.inline-form {
    display: inline;
    height: auto;
    width: auto;
    padding: 3px 10px;
  }
}

select {
  &.form-control {
    padding: 12px $input-padding-x;
  }
}

.form-group {
  label {
    font-family: $type1-regular;
    font-size: 1rem;
    line-height: 1.5;
    vertical-align: top;
    margin-bottom: 2px;
  }

  &.has-danger {
    .form-control {
      border-color: theme-color(danger);
    }
  }

  &.form-relative { position: relative; }

  .icon-end {
    position: absolute;
    right: 1rem;
    height: 18px;
    top: calc((100% + 9px) / 2);
  }
  .code-input {
    display: inline-block;
    width: 45px;
    height: 65px;
    margin: 0px 10px;
    font-size: 1.8rem;
    text-align: center;

    @media (max-width: 576px) {
      width: 30px;
      height: 50px;
      margin: 0 4px;
      font-size: 1rem;
      padding: 16px 10px;
    }
  }
}

.hover-row {
  &:hover {
    background-color: $gray-100;
  }
}

.form-check-input {
  &:disabled ~ .form-check-label {
    color: $black;
  }
}