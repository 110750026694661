.dropdown-menu {
  font-size: $default-font-size;
  &:not(.navbar-dropdown) {
    z-index: 1031;
    max-height: 300px;
    max-width: 500px;
    overflow-x: hidden;
    overflow-y: auto;
    @media (max-width: 576px) {
      max-width: 250px;
    }
  }
  .dropdown-item {
    &:active { background: initial; }
  }
  div {
    .text-link {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}