.stepper-top-spacer {
  height: 25px;
}

.stepper-step {
  margin-top: -110px;

  .stepper-dot {
    border-radius: 50%;
    background-color: $black;
    width: 12px;
    height: 12px;
  }

  .stepper-dot-open {
    border-radius: 50%;
    border: 2px solid $black;
    background-color: $white;
    width: 12px;
    height: 12px;
  }

  .stepper-line {
    background-color: $black;
    width: 2px;
    height: 110px;
  }

  .stepper-spacer {
    height: 110px
  }

  .stepper-text {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
