/* Modals */
.modal-open {
  padding-right: 0 !important; // resets the inline padding right added by Bootstrap
}

.modal {
  .modal-dialog {
    margin: 60px auto;
    @media (max-width: 991px) {
      max-width: calc(100vw - 80px);
    }

    .modal-content {
      border: 0px;
      border-radius: 0px;
      background-color: #fff;

      button.focus-visible {
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 50%);
        outline: 0px !important;
      }

      .modal-header {
        text-align: center;
        border-radius: 0px;
        border-bottom: 0px;
        background: $primary;
        color: white;
        padding: $modal-header-padding-y $modal-header-padding-x;
        align-items: center;

        .modal-title { 
          width: 100%;
        }

        .close {
          padding: 5px 16px;
          span {
            font-size: 30px;
            font-weight: 400;
            color: white;
            opacity: 1;
          }
        }
      }

      .modal-body {
        padding: 40px;
        padding-bottom: 15px;

        img.alert-icon {
          max-width: 170px;
          max-height: 140px;
        }
      }

      .modal-footer { padding: $modal-footer-padding-y $modal-footer-padding-x; }
    }
  }
}