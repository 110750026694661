/* Tables */
.table {
  margin-bottom: 0;
  &.table-striped tbody {
    > tr:nth-child(even) {
     background-color: $white;
     &.active {
       background-color: $secondary;
     }
   }
   > tr:nth-child(odd) { 
     background-color: $gray-100; 
     &.active {
       background-color: $secondary;
     }
   }
 }

 &.table-hover {
  tr, thead {
    border-bottom: 1px solid $gray-400;
  }

  tr.active {
    background-color: lighten($primary, 45%);
  }
 }

  th,
  td {
    vertical-align: middle;
    font-size: 16px;
    padding: 16px;
    line-height: 1.4;
    border-top: 0px;
    border-bottom: 0px solid $gray-400;

    img {
      width: 30px;
      height: 30px;
      border-radius: 100%;
    }

    span {
      &.fas,
      &.fal,
      &.far,
      &.fab,
      &.fad {
        font-size: 16px;
      }
    }

    select {
      height: 38px;
      padding: 0px 14px;
    }

    .logo {
      object-fit: scale-down;
      width: 80px;
      height: 80px;
      border-radius: 10px;
      border: 1px solid $gray-200;
      box-shadow: $box-shadow-sm;
      &.logo-placeholder {
        background-color: $gray-400;
      }
    }
  }

  thead {
    th {
      border-top: 0;
      font-family: $type1-bold;
      font-weight: initial;
      font-size: 1rem;
      white-space: nowrap;
      padding: 18px 15px !important;
      i { margin-left: 0.325rem; }
      border: none;
    }
  }

  &.table-borderless {
    border: none;
    tr, td, th { border: none; }
  }
}

.table-link {
  cursor: pointer;
  line-height: 1.4;

  &:hover {
    text-decoration: underline;
    color: $info !important;
  }
}