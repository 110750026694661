/* Layouts */
.page-body-wrapper {
  padding-top: $navbar-height;

  &.full-page-wrapper {
    .sidebar { display: none }
    .footer,
    .content-wrapper { margin-left: 0; width: 100% }
  }

  .content-wrapper {
    background: $content-bg;
    margin-left: $sidebar-width-lg;
    min-height: calc(100vh - 79px);
    padding: 1.5rem 1.7rem;
    transition: width $action-transition-duration $action-transition-timing-function, margin $action-transition-duration $action-transition-timing-function;
    width: calc(100% - #{$sidebar-width-lg});
    @media (max-width: 991px) {
      margin-left: 0;
      width: 100%;
    }
  }
}

.container-scroller {
  overflow-x: hidden;
  position: relative;
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

//Fixed sidebar
.sidebar-fixed {
  .sidebar {
    @media (min-width: 992px) {
      position: fixed;
    }

    .nav {
      max-height: calc(100vh - #{$navbar-height});
      position: fixed;
      overflow: auto;
      height: 100%;
      width: 240px;
      background-color: white;

      &.sub-menu {
        max-height: none;
      }

      @media (max-width: 576px) {
        width: 100%;
      }
    }
  }
}

@media (min-width: 992px) {
  .container {
      max-width: 980px;
  }
}

@media (min-width: 1250px) {
  .container {
      max-width: 1200px;
  }
}

@media (min-width: 1440px) {
  .container {
      max-width: 1400px;
  }
}
